@import './../../styles/imports';

.PoiResults {
  flex: 1 1 auto;
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.PoiResultItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: $text-primary;
  background: $white;
  border-bottom: 1px solid $grey-100;
  margin: 0;
  padding: $spacing-base * 2;
  cursor: pointer;

  &:hover {
    background: $blue-ultralight;

    .Img {
      background: $blue-tinted-200;
      border-color: $blue-tinted-200;

      // has image
      &[data-has-image='true'] {
        background: none;
      }
    }
  }
  &:focus {
    border: 2px solid $blue-default;
    outline: none;
  }

  .Img {
    $img-size: 22px;

    flex: 0 0 $img-size;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: $img-size;
    height: $img-size;
    background: $blue-ultralight;
    border: 1px solid $grey-100;
    border-radius: $radius-sml;
    margin-right: $spacing-base * 1.5;

    // has image
    &[data-has-image='true'] {
      background: none;
      border: none;
      border-radius: 0;

      > img {
        display: block;
        flex: 0 0 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  .Content {
    flex: 1 1 auto;
    display: block;
    color: inherit;
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 17px;

    .Title {
      font-weight: 600;
    }

    .Text {
      // content not used
    }

    .Footer {
      // content not used
    }
  }
}

@media (max-width: $mobile) {
  .PoiResults {
    display: none;
  }
}
