@import 'images';

/**
 Fonts
 */
$font-family__sans: sans-serif;
$font-family__normal: 'Roboto', sans-serif;
$font-family__condensed: 'Roboto Condensed', sans-serif;

/**
 Shades
 */

// Blue
$blue-default: #006dae;
$blue-default-hover: #0289d4;
$blue-200: #3078cf;
$blue-500: #204f88;
$blue-600: #1d487c;
$blue-700: #183c67;
$blue-800: #133053;
$blue-900: #0f243e;
$blue-tinted-100: #e8eef4;
$blue-tinted-200: #c5d2df;
$blue-ultralight: #f4f6f9;
$blue-lighter: #edeff1;
$blue-light: #a1b6ca;
$blue-med: #00508c;
$blue-dark: #024980;
$blue-ultra-dark: #0f243e;

// Brightness
$grey-900: #333333;
$grey-800: #747474;
$grey-300: #c6c6c6;
$grey-200: #d6d6d6;
$grey-100: #e8e8e8;

$dark-900: #2c3e50;

// Status
$status-error: #ee0220;
$status-error-bg: #ffefeb;
$status-success: #008a25;
$status-warning: #ff9800;
$status-inactive: #bbb;

// text
$black: #000;
$white: #fff;
$text-primary: #333;
$text-secondary: #777;

/**
 Styles
 */

// radius
$radius-sml: 4px;
$radius-med: 8px;
$radius-lrg: 16px;

// spacing
$spacing-base: 8px;

// dimensions
$page-heading-height-mobile: 55px;
$btn-fs-height: 40px;
$btn-icon-size: 40px;
$pill-gap: 10px;

$icon-size-sml: 10px;
$icon-size-med: 16px;
$icon-size-lrg: 24px;

$form-checkbox: 16px;

$container-width: 100%;

$map-container-height: 600px;
$map-drawer-width: 320px;
$map-width: calc(100% - #{$map-drawer-width});
$map-collapsed-height: 40px;

$search-campus-scroller-height: 60px;
$search-campus-scroller-slider-min-width: 600px;
$search-input-height-desktop: 76px;
$search-input-height-mobile: 60px;

$bottom-sheet-collapsed-height: 150px;
$bottom-sheet-expanded-height: calc(100% - #{$map-collapsed-height});

$directions-search-aside-width: 40px;

// layers z-index
$layer-loader: 1;
$layer-direction-search-result-step-marker: 1;
$layer-map-before-after: 1;
$layer-map-overlay: 5;
$layer-map-search: 8;
$layer-map-bottom-sheet: 10;
$layer-modal: 1000;

// shadows
$shadow-default: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
$shadow-strong: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
$shadow-default--focus: 0 0 1px 3px rgba(59, 153, 252, 0.7);

/**
 Responsive
 */
$mobile: 640px;
$tablet: 1000px;
