@import './imports';

/**
 msgBox
 */

.msgBox {
  display: flex;
  color: $text-primary;
  background: $blue-lighter;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: auto;
  min-height: 60px;
  padding: $spacing-base $spacing-base * 1.5;
  overflow: hidden;

  // icon
  .icon-svg {
    margin: 0 18px 0 6px;

    &,
    > svg {
      fill: $status-error;
      display: block;
    }
  }

  // msg text
  &__msg {
    margin: 0;
    padding: 0;
  }

  // variants - error
  &--error {
    $icon-error-size: 20px;

    background: $status-error-bg;

    // icon
    .icon-svg {
      &,
      > svg {
        width: $icon-error-size;
        height: $icon-error-size;
      }
    }
  }

  // add new variants here ...
}
