@import './../../styles/variables';

.small {
  $bar-bg-color: $blue-default; //#74ad31;

  display: inline-block;
  z-index: 1000;
  position: relative;
  width: auto;

  @-webkit-keyframes line-scale-pulse-out-rapid {
    0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
    80% {
      -webkit-transform: scaley(0.3);
      transform: scaley(0.3);
    }
    90% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
  }
  @keyframes line-scale-pulse-out-rapid {
    0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
    80% {
      -webkit-transform: scaley(0.3);
      transform: scaley(0.3);
    }
    90% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
  }

  .line-scale-pulse-out-rapid {
    & > div {
      display: inline-block;
      background: $bar-bg-color;
      width: 3px;
      height: 18px;
      border-radius: 2px;
      margin: 2px;
      vertical-align: middle;

      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
      animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
    }

    & > div:nth-child(2),
    & > div:nth-child(4) {
      -webkit-animation-delay: -0.25s !important;
      animation-delay: -0.25s !important;
    }

    & > div:nth-child(1),
    & > div:nth-child(5) {
      -webkit-animation-delay: 0s !important;
      animation-delay: 0s !important;
    }
  }

  .loader-text {
    display: inline-block;
    position: absolute;
    left: 40px;
    line-height: 25px;
  }
}
