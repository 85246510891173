@import './../../../styles/imports';

.TravelModeBtn {
  @include button-reset();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $grey-800;
  background: $white;
  width: auto;
  min-width: 48px;
  height: $radius-lrg * 2;
  padding: 0;
  border: 1px solid $blue-lighter;
  border-radius: $radius-lrg;

  &:focus {
    border-color: $blue-default;
  }

  // icon
  :global(.icon-svg) {
    &,
    > svg {
      fill: currentColor;
      display: block;
      width: $icon-size-med;
      height: $icon-size-med;
    }
  }

  // active state
  &[data-active='true'] {
    cursor: initial;
    color: $white;
    background: $blue-default;
    border-color: transparent;
  }
}
