@import './../../styles/imports';

.SvgIcon {
  transform-origin: center center;

  &,
  > svg {
    display: block;
    width: $icon-size-med;
    height: $icon-size-med;
  }

  > svg {
    stroke: none;
  }
}
