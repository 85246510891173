@import './../../../styles/imports';

.TravelModeGroup {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 12px auto;

  :global(.btn-travel-mode) {
    flex: 1 1 25%;
    width: 25%;

    // gap in between
    & + :global(.btn-travel-mode) {
      margin-left: $spacing-base * 0.5;
    }
  }
}
