@import './../../styles/imports';

/**
 SearchControlSection
 - universal search control panel
 - this is intended to be used by extra search control groups UI
 - desktop only due to layout design
 */
.SearchControlSection {
  flex: 0 0 100%;
  background: $white;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  width: $map-drawer-width;
  max-width: $map-drawer-width;

  // mobile version is rendered in 'panel-map', NOT in the desktop 'panel-drawer'
  @media (max-width: $mobile) {
    display: none !important;
  }

  // header (optional)
  // - used for search control UI
  .SectionHeader {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    height: auto;
    border-bottom: $spacing-base * 0.5 solid $blue-lighter;
  }

  // main
  // - content children to be displayed
  // - is scrollable
  .SectionMain {
    flex: 1 1 100%;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  // footer (optional)
  // - used for extra content fixed at the bottom
  .SectionFooter {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    height: auto;
  }
}
