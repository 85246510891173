@import './../../../styles/imports';

.TravelInstructions {
  $index-size: $spacing-base * 3;

  display: block;
  position: relative;
  width: 100%;

  > ol {
    @include list-reset-full();

    > li {
      color: $text-primary;
      display: block;
      position: relative;
      margin: 0;
      padding-left: $index-size + $spacing-base * 0.5;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      // custom index text
      &::before {
        content: attr(data-index-text);
        display: block;
        position: absolute;
        top: auto;
        left: 0;
        width: $index-size;
        height: auto;
        margin: 0;
        padding: 0;
        font-weight: inherit;
      }

      // gap in between
      & + li {
        margin-top: $spacing-base * 2;
      }
    }
  }
}
