@import './../../styles/imports';

/**
 Directions search result UI
 - this is shared across desktop and mobile usages
 */
.DirectionsResult {
  $gap-left: $directions-search-aside-width - ($spacing-base * 2);

  display: block;
  position: relative;
  max-width: 100%;
  margin-left: $gap-left; // match desktop search control left padding

  @media (max-width: $mobile) {
    margin-left: 0;
  }

  // contents
  :global([data-dir-result-sec]) {
    & + :global[data-dir-result-sec] {
      margin-top: $spacing-base;
    }
  }
}

.DirectionsResultLoading {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: $blue-200;
  margin: 0 auto;
  padding: $spacing-base * 2;
}

.DirectionsResultError {
  $offset: $spacing-base * -2;

  display: block;
  margin: 0 $offset;

  &[data-instancce='desktop'] {
    margin-top: $offset;
  }
}
