.close {
    float: right;
    &:first-of-type > div{
        width: 30px ;
        height: 30px; 
        svg {
            width: 30px !important ;
            height: 30px  !important;
            fill: #006dae;
        }
    }
}
