@import './../../../styles/imports';

.TravelSteps {
  $marker-size: $spacing-base * 4;
  $connect-line-size: $spacing-base * 0.5;
  $connect-line-left: ($marker-size - $connect-line-size) * 0.5;

  $text-gap: $spacing-base;
  $text-line-height: 20px;
  $text-padding-y: ($marker-size - $text-line-height) * 0.5;

  $item-padding-y: $spacing-base * 1.5;
  $item-bottom-line-left: $marker-size + $text-gap;

  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  // list
  .TravelStepsList {
    margin: $spacing-base * 2 0;
  }

  // item
  .TravelStepItem {
    @include button-reset();

    display: flex;
    position: relative;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: $item-padding-y 0;

    // connecting lines
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: $connect-line-left;
      width: $connect-line-size;
      height: auto;
      background: $blue-default;
    }
    &::before {
      top: 0;
      bottom: 50%;
    }
    &::after {
      top: 50%;
      bottom: 0;
    }

    // marker icon
    :global(.travel-result-steps__item-marker) {
      flex: 0 0 $marker-size;
      width: $marker-size;
      height: $marker-size;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $white;
      background: $blue-default;
      z-index: $layer-direction-search-result-step-marker;

      :global(.icon-svg) {
        color: inherit;

        &,
        > svg {
          fill: currentColor;
          display: block;
          width: $icon-size-med;
          height: $icon-size-med;
        }
      }
    }

    // content
    :global(.travel-result-steps__item-content) {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: $text-padding-y 0;

      // bottom line
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: $item-bottom-line-left;
        right: 0;
        width: auto;
        height: 1px;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        background: $blue-lighter;
      }

      // content children common
      > span {
        display: block;
        color: $text-primary;
        font-size: 14px;
        line-height: $text-line-height;
        text-align: left;
        margin-left: $text-gap;
      }

      // label
      :global(.travel-result-steps__item-label) {
        flex: 0 0 auto;
        max-width: 160px;
      }

      // distance
      :global(.travel-result-steps__item-distance) {
        flex: 0 0 auto;
        max-width: 80px;
      }

      // duration
      :global(.travel-result-steps__item-duration) {
        flex: 1 0 auto;
        text-align: right;
        white-space: nowrap;
      }
    }

    // first item
    &:first-child {
      &::before {
        content: initial;
        display: none;
      }
    }

    // last item
    &:last-child {
      &::after {
        content: initial;
        display: none;
      }
    }

    // variants - step marker type
    &[data-step-marker-type*='BUILDING'] {
      :global(.travel-result-steps__item-marker) {
        color: $dark-900;
        background: $blue-lighter;
      }
    }

    // state
    &:focus {
      outline: none;
      :global(.travel-result-steps__item-marker) {
        border: 2px solid $black;
      }
    }
  }
}
