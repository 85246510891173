@import './../../styles/imports';

// campus select dropdown
.selectCss {
  color: $white;
  display: block;
  width: min-content;
  margin: 0;
  padding: 0 $spacing-base * 4 0 $spacing-base * 2;
  font-size: 13px;
  line-height: 40px; // min height for touch
  text-align: center;
  white-space: nowrap;
  border-radius: 24px;
  border: none;
  outline: none;
  appearance: none;
  box-shadow: $shadow-default;

  // icon using bg
  background: $blue-default url($chevron-down-white) no-repeat
    calc(100% - #{$spacing-base * 2}) center;
  background-size: $icon-size-sml $icon-size-sml;

  // category select
  &.selectCategory {
    width: 100%;
  }
}

.campusContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
}

.selectWrapper {
  padding: 5px 0;
}

.pills {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 100%;
  margin-left: $pill-gap;
  padding: 5px 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  > .pill {
    @include pill-float();

    &:not(:first-of-type) {
      margin-left: $pill-gap;
    }
  }
}
