@import 'variables';

/* list */
@mixin list-reset-full() {
  &,
  > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

/* button */
@mixin button-reset() {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

/* form */
@mixin fieldset-reset() {
  display: block;
  box-sizing: border-box;
  background: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
@mixin input-text-reset() {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;

  &:focus {
    outline: 1px dotted $blue-default;
  }
}
@mixin input-placeholder($color: inherit) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

/* pills */
@mixin pill {
  display: block;
  flex: 0 0 auto;
  color: $black;
  background: $white;
  box-sizing: border-box;
  padding: 0 $spacing-base * 1.5;
  font-size: 13px;
  line-height: 36px; // min height for touch
  border-radius: 24px;
  border: 1px solid $blue-lighter;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  &:focus {
    border-color: $blue-default;
  }
}

@mixin pill-float {
  @include pill;
}

@mixin pill-selected {
  background: $blue-default;
  border: 1px solid $blue-default;
}
