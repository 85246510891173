@import './imports';

/**
 helpers
 */

// for general content padded container
.padded-container {
  padding: $spacing-base * 2;
}

// applies bottom border for sub content section
.sub-sec-border {
  border-bottom: $spacing-base * 0.5 solid $blue-lighter;
}
