@import './../../styles/imports';

.FormCheckbox {
  // fieldset
  @include fieldset-reset();

  // label
  :global(label.form-checkbox-label) {
    $padding: $spacing-base;
    $padding-left: $form-checkbox + $spacing-base * 1.5;

    display: inline-block;
    position: relative;
    margin: 0;
    padding: $padding 0 $padding $padding-left;
    border: 2px solid transparent;
    color: $text-primary;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    // fake checkbox
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: $padding + 2px;
      left: 0;
      width: $form-checkbox;
      height: $form-checkbox;
      font-size: 0;
      line-height: 0;
      border: 2px solid $blue-default;
      border-radius: $radius-sml * 0.5;
    }

    // states
    &[data-focused='true'] {
      border-color: $blue-default;
    }
    &[data-checked='true'] {
      // add fill and icon
      &::before {
        background-color: $blue-default;
        background-image: url($tick-white);
        background-size: $form-checkbox $form-checkbox;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
