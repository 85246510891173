@import './../../styles/imports';

/**
 Root container - initial search control
 */
.SearchWrapper {
  display: flex;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  @media (max-width: $mobile) {
    display: block;
    flex: none;
    max-height: none;
    overflow: visible;
  }
}

/**
 SearchControlDefault
 - default control and results
 */
.SearchControlDefault {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  padding: $spacing-base * 2 $pill-gap $spacing-base;
  z-index: 1;
  overflow: visible; // for auto suggestions
  @media (max-width: $mobile) {
    flex: none;
    padding: 0;
    margin-bottom: $pill-gap * 0.5;
  }
}

/**
 SearchInput
 */
.SearchInputWrapper {
  $icon-offset: 6px;

  display: block;
  position: relative;

  // icons / buttons
  .IconSearch,
  .IconClear {
    display: block;
    position: absolute;
    top: $icon-offset;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $icon-size-med $icon-size-med;

    // reset button
    background-color: transparent;
    border: 0;
    outline: none;
    appearance: none;
  }
  .IconSearch {
    left: $icon-offset;
    background-image: url($search-dark);
  }
  .IconClear {
    right: $icon-offset;
    background-image: url($first-aid-dark);
    border-radius: 50%;
    transform-origin: center center;
    transform: rotate(45deg);

    &:focus {
      border: 2px solid $blue-default;
    }
  }
  .IconBtnBackWrapper {
    display: block;
    position: absolute;
    top: $icon-offset;
    left: $icon-offset;
  }

  // search loader container
  .SearchLoaderWrapper {
    display: block;
    position: absolute;
    top: 14px;
    right: 50px;
    width: auto;
    height: auto;
    z-index: $layer-loader;
  }
}
.SearchInput {
  $lineHeight: 20px;
  $paddingY: $spacing-base * 2;
  $paddingL: $spacing-base * 6; // for search icon
  $paddingR: $spacing-base * 5; // for clear icon
  $height: $lineHeight + $paddingY * 2;

  display: block;
  width: 100%;
  height: $height;
  max-height: $height;
  margin: 0 auto;
  padding: $paddingY $paddingR $paddingY $paddingL;
  color: $black;
  background: $white;
  box-sizing: border-box;
  font-size: 14px;
  line-height: $lineHeight;
  border-radius: 30px;
  border: 1px solid $blue-lighter;
  box-shadow: $shadow-default;
  outline: none;
  -webkit-appearance: none; // fixes missing box-shadow on iOS

  &:focus {
    border: 2px solid $blue-default;
  }
}

/**
 SearchOptions
 - extra controls and search results
 */
.SearchControlExtra {
  flex: 0 0 auto;
  position: relative;

  @media (max-width: $mobile) {
    flex: none;
  }
}

/**
 SearchSuggestions
 */
.SearchSuggestions:global(.search-suggestions.default) {
  position: relative;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  margin-top: $spacing-base;
  box-shadow: none;
  border-radius: 0;
  overflow: auto;
  max-height: none;
  -webkit-overflow-scrolling: touch;

  // results num
  :global(ul.search-suggestions-list.default .search-results-num) {
    color: $text-secondary;
    background: $white;
    width: 100%;
    margin: 0;
    padding: $spacing-base * 2;
    padding-bottom: 0;
    border: none;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: $mobile) {
      padding: $spacing-base 0;
    }
  }

  // item
  :global(ul.search-suggestions-list.default .item) {
    color: $text-primary;
    background: $white;
    position: relative;
    width: 100%;
    margin: 0;
    padding: $spacing-base * 2;
    border: none;
    border-bottom: 1px solid $grey-100;
    font-size: 14px;
    line-height: 17px;

    &:hover {
      background: $blue-ultralight;
    }
    &:focus {
      border: 2px solid $blue-default;
      outline: none;
    }

    :global(.title) {
      font-weight: 600;
    }

    :global(.context.floor) {
      color: $blue-default;
      font-weight: 600;
    }

    :global(.context.building) {
      display: inline-block;
      color: $text-secondary;
      margin-top: $spacing-base * 0.5;
      font-size: 12px;
    }

    :global(.context.campus) {
      color: $text-secondary;
      margin-top: $spacing-base * 0.5;
      font-size: 12px;
    }

    :global(.context.category) {
      color: $text-secondary;
      margin-top: $spacing-base * 0.5;
      font-size: 12px;
    }

    :global(.context.detail-secondary) {
      display: block;
      color: $text-secondary;
      font-size: 12px;
    }

    :global(.context.detail-dispPoiNames) {
      display: block;
      color: $text-secondary;
      margin-top: $spacing-base * 0.5;
      font-size: 12px;
    }

    :global(.context.detail-dispDistanceToCampus),
    :global(.context.detail-dispDistanceToGeolocation) {
      display: block;
      color: $text-secondary;
      margin-top: $spacing-base * 0.5;
      font-size: 12px;
    }
    :global(.context.detail-dispDistanceToGeolocation) {
      color: $blue-default-hover;
    }

    @media (max-width: $mobile) {
      padding: $spacing-base * 2 0;
    }

    // icon variant
    &[data-has-icon='true'] {
      padding-left: $spacing-base * 6.5;

      :global(.icon-svg) {
        position: absolute;
        top: 50%;
        left: $spacing-base * 2;
        margin-top: $icon-size-lrg * -0.5;

        &,
        > svg,
        > img {
          display: block;
          width: $icon-size-lrg;
          height: $icon-size-lrg;
        }
      }

      @media (max-width: $mobile) {
        padding-left: $spacing-base * 4.5;
        padding-right: 0;

        :global(.icon-svg) {
          left: 0;
        }
      }
    }

    // Open Day 2022
    &:global(.OpenDayIcon) { 
      padding-left: 45px;
      background-size: 55px 25px;
      background-repeat: no-repeat;
      background-position: left center;    
      @media (max-width: $mobile) {
        padding-left: 30px;
        background-size: 25px 25px;
      }
    }

  }
}

/**
 CampusScroller
 */
.CampusScroller {
  width: 100%;
  height: auto;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;

  // usage: search result
  &[data-usage='search-result'] {
    margin-top: -$spacing-base;

    @media (max-width: $mobile) {
      margin-top: $spacing-base * -0.5;
    }
  }

  // slider
  :global .slick-slider {
    padding: 0 18px;

    // buttons
    button.slick-arrow {
      &.slick-prev,
      &.slick-next {
        color: $black;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $icon-size-sml $icon-size-sml;

        // icon
        &::before {
          content: '';
        }

        // state
        &.slick-disabled {
          opacity: 0.25;
        }
      }
      &.slick-prev {
        background-image: url($chevron-left-dark);
        left: 0;
      }
      &.slick-next {
        background-image: url($chevron-right-dark);
        right: 0;
      }
    }

    // track
    &.slick-initialized {
      .slick-list .slick-track {
        min-width: $search-campus-scroller-slider-min-width;
      }
    }
  }
}

/**
 CampusPill
 */
.CampusPill {
  display: block;
  position: relative;
  margin: $pill-gap 0;
  padding: 0;
  white-space: nowrap;

  &:not(:first-of-type) {
    margin-left: $pill-gap;
  }

  // selected state
  input[type='radio']:checked + label {
    color: $white;
    background: $blue-default;
    cursor: default;
    pointer-events: none;
  }

  // pill
  label {
    @include pill-float();
    background: $blue-tinted-100;
    margin: 0 $spacing-base * 0.5;
  }

  // disabled
  input[type='radio'][disabled] {
    pointer-events: none;

    & + label {
      color: $blue-light;
      background: $blue-ultralight;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:checked + label {
      color: $white;
      background: $blue-tinted-200;
    }
  }
}