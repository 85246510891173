@import './../../styles/imports';

/**
 ShareMapDeepLink
 */
.ShareMapDeepLink {
  display: block;
  position: relative;
  padding-top: $spacing-base;
}
.ShareMapDeepLinkTitle {
  display: block;
  margin: $spacing-base auto;
  color: $text-primary;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
.ShareMapDeepLinkUrl {
  display: block;
  width: 100%;
  height: auto;
  max-height: none;
  padding: $spacing-base;
  color: $grey-800;
  background: $blue-ultralight;
  border-radius: $radius-sml;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  border: none;
  cursor: pointer;
  overflow: hidden;
}
.ShareMapDeepLinkCopied {
  display: inline-flex;
  font-weight: 700;
  color: $blue-default;
  float: right;
  clear: right;
  margin-left: $spacing-base * 0.5;
}
