@import './../../../styles/imports';

.TravelCTAs {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  :global(.btnCTA) {
    display: flex;
    flex: 1 1 50%;
    width: 50%;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
