@import './../../styles/imports';

.BtnBack {
  @include button-reset();

  display: flex;
  align-items: center;
  justify-content: center;
  width: $btn-icon-size;
  height: $btn-icon-size;
  border: 2px solid transparent;
  overflow: hidden;

  &:focus {
    border-color: $blue-default;
    outline: none;
  }

  :global(.icon-svg) {
    &,
    > svg {
      display: block;
      width: $icon-size-lrg;
      height: $icon-size-lrg;
    }
  }

  // round
  &[data-is-round='true'] {
    border-radius: 50%;
  }

  // disabled
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
