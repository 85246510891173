@import './../../styles/imports';

/**
 Directions search control UI
 - this is shared across desktop and mobile usages
 */
.DirectionsSearch {
  $container-padding: $spacing-base * 2;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: $white;
  margin: 0;
  padding: $container-padding $container-padding $container-padding 0;

  @media (max-width: $mobile) {
    display: flex;
  }

  // Layout - aside
  .Aside {
    position: relative;
    flex: 0 0 $directions-search-aside-width;
    width: $directions-search-aside-width;
  }

  // Layout - main
  .Main {
    position: relative;
    flex: 1 1 auto;
    width: auto;
  }

  // main controls - TravelInputs
  .TravelInputs {
    $container-padding-x: 12px;
    $container-padding-y: $spacing-base;
    $btn-swap-size: 32px;
    $label-width: 40px;

    display: block;
    position: relative;
    background: $blue-lighter;
    border-radius: $radius-sml;
    padding: $container-padding-y ($container-padding-x + $btn-swap-size)
      $container-padding-y $container-padding-x;

    .TravelInputWrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      // center line
      &[data-for='to'] {
        border-top: 1px solid $white;
      }

      .TravelInputLabel {
        display: block;
        flex: 0 0 $label-width;
        width: $label-width;
        color: $text-secondary;
        font-size: 12px;
        line-height: 15px;
      }

      .TravelInput {
        $input-base-width: 120px;

        @include input-text-reset();
        @include input-placeholder($blue-default);

        display: block;
        flex: 1 1 $input-base-width;
        width: $input-base-width;
        height: auto;
        margin-right: $spacing-base;
        padding: $spacing-base 0;
        color: $text-primary;
        font-size: 14px;
        line-height: 20px;
        cursor: not-allowed;

        // variants
        &[data-variant='geolocate'] {
          color: $blue-default-hover;
          font-weight: bold;
        }

        // state
        &[disabled] {
          cursor: not-allowed;
        }
      }

      .TravelInputGeoBtn {
        $btnSize: $spacing-base * 4;
        $iconSize: $icon-size-med;

        @include button-reset();

        display: flex;
        align-items: center;
        justify-content: center;
        width: $btnSize;
        height: $btnSize;
        color: $text-primary;

        &:hover {
          color: $blue-default-hover;
        }
        &:focus {
          outline: 1px dotted currentColor;
        }

        // icon
        :global(.icon-svg) {
          &,
          > svg {
            color: inherit;
            fill: currentColor;
            width: $iconSize;
            height: $iconSize;
          }
        }

        // state
        &[disabled] {
          color: $grey-800 !important;
          cursor: not-allowed;
        }
        &[data-state='loading'] {
          animation: rotation 2s infinite linear;
        }
        &[data-state='active'] {
          color: $blue-default-hover !important;
        }
      }
    }

    .BtnSwap {
      @include button-reset();

      position: absolute;
      top: calc(50% - #{$btn-swap-size * 0.5});
      right: $container-padding-x;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      width: $btn-swap-size;
      height: $btn-swap-size;
      border-radius: 50%;
      border: 2px solid transparent;
      overflow: hidden;

      :global(.icon-svg) {
        &,
        > svg {
          display: block;
          width: $icon-size-med;
          height: $icon-size-med;
        }
      }

      &:focus {
        border-color: $blue-default;
        outline: none;
      }
    }
  }

  // main controls - TravelModes
  .TravelModes {
    display: block;
  }

  // main controls - TravelOptions
  .TravelOptions {
    display: block;
  }
}
