@import 'styles/imports';
@import 'styles/base';
@import 'styles/buttons';
@import 'styles/msg-box';
@import 'styles/mapboxgl-popup';
@import 'styles/helpers'; // please always keep this as last

.app-way-finding {
  // main structure
  .container {
    display: block;
    position: relative;
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 $spacing-base * 2;

    .content-header {
      display: block;
      max-width: 70%;
      margin: 0 auto;
      padding: $spacing-base * 8 0;
      color: $black;
      text-align: center;

      @media (max-width: $mobile) {
        padding: $spacing-base * 4 0;
        max-width: none;
      }
    }
  }

  // map container
  .map-container {
    background: $grey-300;
    border: 1px solid $grey-300;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: $container-width;
    height: calc(100vh - 280px); // height: auto;
    margin: 0 auto;
    overflow: hidden;
    min-height: 250px;

    &.fs {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &.embedded {
      //hide panel and button when embedded
      > .panel-drawer {
        display: none;
      }
      button.mapboxgl-ctrl-icon.mapboxgl-ctrl-fullscreen {
        display: none;
      }
    }

    @media (max-width: $mobile) {
      display: block;
      height: auto;
    }

    // map drawer
    .panel-drawer {
      background: $white;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      position: relative;
      flex: 0 0 $map-drawer-width;
      width: $map-drawer-width;
      height: auto;
      margin: 0 auto;
      padding: 0;
      overflow: auto;

      @media (max-width: $mobile) {
        // make it float on top
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: none;
        flex: none;
        width: auto;
        padding: $pill-gap;
        z-index: $layer-map-search;
        overflow: visible; // for auto suggestions
      }
    }

    // map
    .panel-map {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      position: relative;
      flex: 0 0 $map-width;
      width: $map-width;
      height: auto;
      overflow: hidden;

      // edge shadow
      &::after {
        content: '';
        color: transparent;
        background: linear-gradient(to right, #{$black}, transparent);
        opacity: 0.06;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 8px;
        height: auto;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
      }

      // mobile design requires different layout
      @media (max-width: $mobile) {
        flex: none;
        width: auto;
        height: $map-container-height;

        html[data-mobile-fs-allowed='false'] & {
          height: $map-container-height + $page-heading-height-mobile +
            $btn-fs-height;
        }

        &::after {
          content: none;
          display: none;
        }
      }

      // fullscreen
      &.fs {
        height: 100%;

        @media (max-width: $mobile) {
          height: calc(100% - #{$btn-fs-height});
        }
      }

      // embedded
      &.embedded {
        width: 100%;
        flex-basis: 100%;
      }

      // map panel areas
      // mobile only
      .panel-map-area {
        display: none;
        position: relative;

        @media (max-width: $mobile) {
          display: block;
          flex: 0 0 auto;
          width: 100%;
          height: auto;
        }

        // map area
        &[data-area='map'] {
          display: block;
          flex: 1 1 100%; // desktop full height
          width: 100%;
          overflow: hidden;

          @media (max-width: $mobile) {
            flex-basis: auto; // mobile flexible height
          }
        }

        // before/after areas common
        &[data-area='before'],
        &[data-area='after'] {
          // need to be on top of map canvas
          z-index: $layer-map-before-after;

          &::before {
            content: '';
            color: transparent;
            opacity: 0.06;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            width: auto;
            height: 8px;
            font-size: 0;
            line-height: 0;
            overflow: hidden;
          }
        }

        // before map area
        &[data-area='before'] {
          // edge shadow
          &::before {
            background: linear-gradient(to bottom, #{$black}, transparent);
            bottom: -8px;
          }
        }

        // after map area
        &[data-area='after'] {
          height: $bottom-sheet-collapsed-height;
          max-height: $bottom-sheet-collapsed-height;
          flex-basis: $bottom-sheet-collapsed-height;

          // edge shadow
          &::before {
            background: linear-gradient(to bottom, transparent, #{$black});
            top: -8px;
          }

          @media (max-width: $mobile) {
            display: flex;
          }
        }

        // overlay
        &[data-area='overlay'] {
          $padding-x: $spacing-base * 2;

          display: none;
          position: absolute;
          background: $white;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: $layer-map-overlay;

          @media (max-width: $mobile) {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            max-width: 100%;
          }

          // main content container
          .panel-map-overlay__content {
            flex: 1 1 100%;
            display: block;
            width: 100%;
            height: auto;
            padding: $search-input-height-mobile $padding-x 0 $padding-x; // leave top space for search input control
            overflow: auto;
            -webkit-overflow-scrolling: touch;
          }

          // footer container
          .panel-map-overlay__footer {
            flex: 0 0 auto;
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }

      // bottom sheet expanded
      &[data-bottom-sheet-state='expanded'] {
        .panel-map-area {
          // expand after
          &[data-area='after'] {
            height: $bottom-sheet-expanded-height;
            max-height: $bottom-sheet-expanded-height;
            flex-basis: $bottom-sheet-expanded-height;
          }
        }
      }
    }
  }

  // misc
  .mobile-fs-button {
    @include button-reset();

    display: none;
    color: white;
    background: $blue-default;
    width: 100%;
    height: $btn-fs-height;
    padding: 0 0.5rem;
    font-size: 14px;
    line-height: $btn-fs-height;
    white-space: nowrap;

    // icon
    .icon-svg {
      margin-right: $spacing-base;

      &,
      > svg {
        width: $icon-size-lrg;
        height: $icon-size-lrg;
      }
    }

    @media (max-width: $mobile) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.embedded {
      display: none;
    }
  }
}

/*
  mobile without desktop
*/
@media (max-width: $mobile) {
  button.mapboxgl-ctrl-icon.mapboxgl-ctrl-fullscreen {
    display: none;
  }
}

/*
  when the map request fullscreen mode
  hides the header/footer
*/
#page-wrapper.fs {
  color: inherit;

  // occupy fullscreen
  #main {
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;

    .content {
      max-width: 100%;
    }

    .app-way-finding {
      margin: 0;
      padding: 0;
    }
  }

  // hide others
  #top.skip-links,
  header#hdr,
  footer#ftr,
  #digital-wayfinding-dpp-notice.dwdppNotice {
    display: none !important;
  }
}

/**
 DPP Notice Banner
 */
#digital-wayfinding-dpp-notice.dwdppNotice {
  $dwdppNotice: '.dwdppNotice';

  color: $blue-lighter;
  background: #3d3d3d;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: $mobile) {
    font-size: 11px;
    line-height: 13px;
  }

  p {
    color: rgb(213, 210, 210);
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: $spacing-base 0 0 0;

    &.dwdppNotice__initial {
      padding: 0;
    }

    a {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }
  }

  #{$dwdppNotice}__wrapper {
    $side-paddings: $spacing-base * 4;

    max-width: 900px + $side-paddings * 2;
    margin: 0 auto;
    padding: $spacing-base * 2 $side-paddings;
    overflow: hidden;

    @media (max-width: $mobile) {
      padding: $spacing-base * 2;
    }
  }

  #{$dwdppNotice}__toggle {
    @include button-reset();

    color: inherit;
    font-size: inherit;
    font-weight: 600;
    line-height: inherit;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: underline;
    }

    &[data-shown='inactive'] {
      display: inline-block;
    }
    &[data-shown='active'] {
      display: none;
    }
  }

  #{$dwdppNotice}__extra {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }

  // active
  &[data-active='true'] {
    #{$dwdppNotice}__extra {
      max-height: 450px;
    }

    #{$dwdppNotice}__toggle {
      &[data-shown='inactive'] {
        display: none;
      }
      &[data-shown='active'] {
        display: inline-block;
      }
    }
  }
}

/* graphite footer accessibility fixes */
footer#ftr {
  h3.graphite-subheading,
  p.graphite-intro,
  div.graphite-legal,
  p.graphite-legal {
    color: rgb(193, 191, 191);
  }
}

// full screen styles
html.fs {
  width: 100%;
  height: 100vh;

  > body {
    width: 100%;
    height: 100vh;
    overflow: hidden; // Note: this prevents #longContentPlaceholder actually cause a page scroll

    // Note: this tricks iOS Safari to extend its viewport canvas to actual full screen height
    // because there is actual page content overflowing 100vh
    #longContentPlaceholder {
      display: block !important;
      position: static;
      width: 100%;
      height: 150vh;
    }

    // hide other body children
    #search-panel,
    #nav-drawer.nav-drawer {
      display: none !important;
    }
  }
}
#longContentPlaceholder {
  display: none;
  width: 0;
  height: 0;
}
