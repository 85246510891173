@import 'imports';

// APP
.app-way-finding,
#root {
  font-family: $font-family__normal;
  height: 100%;
}

main {
  display: flex;
  width: 100%;
}

// TYPOGRAPHY
h1 {
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0;
  padding-top: 0;
}

h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
}
h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
}
.text-intro {
  font-size: 22px;
  line-height: 33px;

  @media (max-width: $mobile) {
    font-size: 18px;
    line-height: 24px;
  }
}

// UTILITIES
.hidden {
  display: none !important;
  visibility: hidden;
}

.sr-only,
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.white-space--pre-wrap {
  white-space: pre-wrap;
}
