@import './../../styles/imports';

.BtnReportIssue {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-default;
  width: 100%;
  height: auto;
  padding: $spacing-base * 2;
  border: 2px solid transparent;
  border-top-color: $grey-100;
  font-size: 11px;
  line-height: $spacing-base * 2;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    border-color: $blue-default;
  }
}
