@import './../../styles/imports';

.PoiDetails {
  padding: $spacing-base * 2;
  /* overflow fix for longer content */
  height: auto;
  overflow: auto;

  @media (max-width: $mobile) {
    padding: 0;
  }

  // overwrite category details results view
  :global(.search-suggestions.default) {
    margin-left: $spacing-base * -2;
    margin-right: $spacing-base * -2;

    @media (max-width: $mobile) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// main content blocks
.PoiDetailsPrimary {
  font-family: $font-family__condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  padding-top: $spacing-base * 0.5;
}

.PoiDetailsSecondary,
.PoiDetailsExtraContent {
  font-family: $font-family__normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
}
.PoiDetailsSecondary {
  padding: $spacing-base 0;
}

// content elements
.PoiDetailsCTAs {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  :global(.btnCTA) {
    display: flex;
    flex: 1 1 50%;
    width: 50%;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.PoiDetailsTypes,
.PoiDetailsDispPoiNames {
  display: block;
  color: $text-secondary;
  font-size: 11px;
}
.PoiDetailsDesc,
.PoiDetailsUrl {
  display: block;
  margin: $spacing-base 0;
}
.PoiDetailsImages {
  display: block;
}
.PoiDetailsImageLink {
  &,
  > img {
    display: block;
    width: 100%;
    height: auto;
  }
}
