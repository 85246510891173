@import './../../../styles/imports';

.TravelMetrics {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > div {
    color: $text-primary;
    display: block;
    flex: 0 0 auto;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .TravelMetricsDuration {
    font-family: $font-family__condensed;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    margin-right: $spacing-base * 2.5;
  }

  .TravelMetricsMode {
    :global(.icon-svg) {
      margin-right: $spacing-base * 0.5;

      &,
      > svg {
        fill: currentColor;
        display: block;
        width: $icon-size-med;
        height: $icon-size-med;
      }
    }
  }

  .TravelMetricsDistance {
    font-size: 14px;
    line-height: 20px;
  }
}
