@import './../../styles/imports';

/**
 BottomSheet this is for mobile only
 */
.BottomSheet {
  display: none;
  position: relative;
  background: $white;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;

  @media (max-width: $mobile) {
    display: flex;
  }

  .Header {
    flex: 0 0 auto;
    display: block;
    text-align: center;

    button.BtnToggle {
      $iconSize: $icon-size-lrg;

      @include button-reset();

      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      width: 100%;
      height: $iconSize;
      border: 2px solid transparent;
      overflow: hidden;

      &:focus {
        border-color: $blue-default;
      }

      // icon
      :global(.icon-svg) {
        &,
        > svg {
          width: $iconSize;
          height: $iconSize;
        }
      }
    }
  }

  .Content {
    flex: 1 1 100%;
    display: block;
    padding: 0 $spacing-base * 2 $spacing-base * 2 $spacing-base * 2;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .Footer {
    flex: 0 0 auto;
    display: block;
  }

  // sheet hidden
  &[data-state='hidden'] {
    display: none !important;
    visibility: hidden;

    // make sure top content is shown without scrolling
    .Content {
      overflow: hidden;
      -webkit-overflow-scrolling: initial;
    }
  }

  // sheet collapsed
  &[data-state='collapsed'] {
  }

  // sheet expanded
  &[data-state='expanded'] {
    z-index: $layer-map-bottom-sheet;

    .Header {
      button.BtnToggle {
        // icon
        :global(.icon-svg) {
          transform: rotate(180deg);
        }
      }
    }
  }
}
