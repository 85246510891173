@import './../../styles/imports';

/**
 ShareQRCode
 */
.ShareQRCodeContainer {
  display: block;
  position: relative;
  max-width: 160px;
  margin: 0 auto;
  padding-top: $spacing-base * 2;
}
.ShareQRCode {
  display: block;
  position: relative;
  width: 110px + $spacing-base;
  padding: $spacing-base * 0.5;
  border: 2px solid $grey-800;
  border-radius: $radius-med;
  margin: 0 auto;

  :global(.img-qr-code-share-display) {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }
}
.ShareQRCodeText {
  color: $text-primary;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}
