.body {
  color: #fff;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0 0 0, 0.5);
  padding: 0;
  min-height: 100%;
  -webkit-box-shadow: inset 0 0 100px rgba(0 0 0, 0.8);
  box-shadow: inset 0 0 100px rgba(0 0 0, 0.8);
  display: table;
  font-family: 'Open Sans', Arial, sans-serif;
  background-color: #21232a;
  width: 100%;
}

.cover {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
  padding-bottom: 10rem;
}

.lead {
  color: silver;
  font-size: 21px;
  line-height: 1.4;
}

.footer {
  position: fixed;
  width: 100%;
  height: 40px;
  left: 0;
  bottom: 0;
  color: #a0a0a0;
  font-size: 14px;
}

.cover a {
  text-decoration: none;
  color: #fff;
  font-size: inherit;
  border-bottom: dotted 1px #707070;
}

.cover h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-size: 36px;
  margin: 0.6rem 0;
}

.cover small {
  font-size: 68%;
  font-weight: 400;
  line-height: 1;
  color: #777;
}

.error {
  font-size: 14px;
  margin-top: 3rem !important;
  color:yellow;
}
@media only screen and (max-width: 600px) {
  .cover {
    display: block;
    margin-top: 25%;
    padding-bottom: 0;
  }

  .lead {
    font-size: 16px;
  }

  .error {
    margin-top: 2rem !important;
  }
}


.btn {
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none !important;
    border: 2px solid transparent;
    color: #fff;
    background: #006dae;
    margin: 10px 5px;
}