@import './../../styles/imports';

/**
 SimpleAccordion
 */
.SimpleAccordion {
  display: block;
  position: relative;

  .Trigger {
    display: block;
  }

  .Content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-top: $spacing-base;

    // default state - collapsed
    display: none;
  }

  // state - expanded
  &[data-expanded='true'] {
    .Content {
      display: block;
    }
  }
}
