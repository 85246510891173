@import './imports';

/**
 buttons
 */

$btn-cta-height: 40px;

.btnLink {
  @include button-reset();

  color: $blue-default;
  display: inline-flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 16px;
  height: 16px;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }

  // icon
  .icon-svg {
    position: relative;
    top: 1px;
    margin-left: $spacing-base * 0.5;

    &,
    > svg {
      fill: $text-primary;
      display: block;
      width: $icon-size-med;
      height: $icon-size-med;
    }
  }

  // state
  &[data-expanded='true'] {
    .icon-svg {
      transform: rotate(180deg);
    }
  }
}

.btnCTA {
  @include button-reset();

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
  height: $btn-cta-height;
  margin: $spacing-base * 0.5;
  padding: $spacing-base * 0.5;
  line-height: $btn-cta-height;
  font-size: 14px;
  font-weight: 400;
  text-indent: -1px;
  text-decoration: none !important;
  border: 2px solid transparent;
  overflow: hidden;

  // variants - primary
  &,
  &[data-variant='primary'] {
    color: $white;
    background: $blue-default;

    &:hover {
      background: $blue-default-hover;
    }
    &:focus {
      outline: 1px solid $black;
    }

    // state - disabled
    &[disabled] {
      &,
      &:hover {
        color: $white;
        background: $status-inactive;
      }
    }
  }

  // variants - secondary
  &[data-variant='secondary'] {
    color: $blue-default;
    background: $white;
    border-color: currentColor;

    &:hover {
      color: $blue-default-hover;
    }
    &:focus {
      outline: 1px solid $black;
    }

    // state - disabled
    &[disabled] {
      &,
      &:hover {
        color: $status-inactive;
        background: $white;
      }
    }
  }
}
