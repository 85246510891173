@import 'imports';

$mapboxgl: '.mapboxgl';

#{$mapboxgl}-popup {
  &-content {
    .map-route-popup {
      // container
      &-container {
        display: block;
      }

      // label
      &__label {
        display: block;
        width: 100%;
        margin-top: $spacing-base * 0.5;
        margin-bottom: $spacing-base;
        color: $text-secondary;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }

      // cta
      &__cta {
        @include button-reset();

        display: block;
        color: $text-primary;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        width: 100%;
        min-width: 140px;
        padding: $spacing-base * 2;

        &[data-point='end'] {
          border-top: 1px solid $blue-lighter;
        }
      }
    }
  }
}
