@import 'styles/imports';

// mazemap category marker
div.marker.mapboxgl-marker[data-type='category'] {
  border-radius: 50%;
  box-shadow: 0 0 0 2px $white, 0 0 8px 2px rgba($black, 0.4);

  &[data-user-selected='true'] {
    box-shadow: 0 0 0 2px $white, 0 0 12px 2px rgba($black, 0.7);
  }
}

/*
 ReactModal__Content ReactModal__Content--after-open
 */
.ReactModal__Overlay {
  z-index: $layer-modal !important;

  > .ReactModal__Content {
    position: fixed !important;
    inset: auto !important ; // 50% auto auto 50% !important;

    // background: rgb(255, 255, 255) !important;
    // overflow: auto !important;
    // outline: none !important;
    padding: 5px 9px !important;
    margin-right: auto !important; //-50% !important;
    transform: none !important;
    width: 100% !important;
    height: 100% !important;
    background: whitesmoke !important;
  }
}

/**
 Mobile layout Graphite header updates
 */
html[data-mobile-fs-allowed='false'] {
  @media (max-width: $mobile) {
    .hdr .hdr-main .hdr-main__main {
      display: none;
    }
  }
}
